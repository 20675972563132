.app_sell.flex {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  overflow: hidden;
  background-color: #fff;
  .wrap {
    width: 720px;

    .sell_loading {
      margin: 56px 0 425px;
    }
  }

  .app_pointer_event_none {
    display: none !important;
  }

  .ui.header {
    margin: 0;
    font-size: 26px;
    line-height: $app-line-height-main;
    color: $app-color-black;
  }

  .w__field_raio {
    display: flex;

    & > div {
      padding: {
        right: 20px;
      }
    }
  }

  // 出品方法誘導文言のCSS追加 柳生
  label.flex.attention {
    margin-bottom: 0 !important;
    font-family: sans-serif !important;
    font-size: 12px;

    a:hover {
      opacity: 0.7;

      @include mq_down('sm') {
        opacity: 1;
      }
    }
  }

  .field_wrapper {
    a#error {
      pointer-events: none;
    }

    .prefecture {
      margin: {
        bottom: 6px;
      }
    }

    &.is_nature {
      padding: {
        bottom: 0 !important;
      }
    }

    .is_ikejime-wrapper {
      display: flex;
      flex-direction: column;
      height: 120px;
    }
    .word_count {
      margin: {
        left: 10px;
      }
    }
    .word_count_under {
      display: block;
      text-align: right;
    }
    .close_bid_min {
      display: flex;
      width: 100%;
      .field-group {
        width: 40%;
      }
    }
  }

  .field_wrapper.image {
    display: none;
    margin-top: -20px;
    text-align: center;
  }

  .description {
    padding-bottom: 25px !important;
    textarea {
      padding: 11px 16px;
    }
  }

  .bidder_user_message_card {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 25vh;
    font-size: 18px;
    font-weight: bold;
    line-height: $app-line-height-main;
    color: $app-color-black;
    text-align: center;
  }

  .w__advise-register {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 475px;
    @include mq_down('sm') {
      min-height: 598px;
    }
    padding: 10px 0;
    .bidder_user_message_card {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 25vh;
      font-size: 18px;
      font-weight: bold;
      line-height: $app-line-height-main;
      color: $app-color-black;
      text-align: center;
    }
  }

  .app_hidden {
    display: none;
  }

  .app_titlebar {
    width: 100%;
    height: auto;
    background-color: white;
  }

  form.flex {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 540px;
    margin: 0 auto;
    font-size: 16px;
    background-color: white;

    .ui.input {
      // semantic-ui-reactのcss上書き
      width: 550px;
    }

    .ui.selection.dropdown {
      // semantic-ui-reactのcss上書き
      width: 550px;
      padding: 0.78571429em 2.1em 0.78571429em 1em;
    }

    .ui.fluid.dropdown > .dropdown.icon {
      position: static;
      float: right;
      padding: 0.55em 0 0;
      // semantic-ui-reactのcss上書き
      font-size: 25px;
      color: $app-color-black;
    }

    i.dropdown.icon {
      top: 0;
      right: 10px;
      font-size: 25px;
      color: $app-color-black;
    }

    .ui.dropdown {
      &.area {
        .visible.menu.transition {
          max-height: 180px !important;
        }
      }
      &.prefecture {
        .visible.menu.transition {
          max-height: 228px !important;
        }
      }
      &.expired {
        .visible.menu.transition {
          max-height: 228px !important;
        }
        width: 100% !important;
      }
      &.save_method,
      &.timing {
        .visible.menu.transition {
          max-height: 228px !important;
        }
      }
    }

    .ui.checkbox {
      &.w__is_ikejime {
        padding: 4px 0 !important;
        color: $app-color-black;
      }
      &.w__is-vaccum {
        padding: 4px 0 !important;
        color: $app-color-black;
      }
    }

    .ui.basic.label.label {
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 34px;
      padding: 0 0.5em;
      color: $app-color-black;
    }

    .dropzone.flex {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 300px;
      height: 300px;
      padding: 61px 0;
      margin-bottom: 6px;
      cursor: pointer;
      border: solid 1px #ccc;
      border-radius: 5px;

      > span {
        color: $app-color-black;
        text-align: center;
      }
    }

    .dropzone_mobile {
      display: none;
    }

    .point {
      margin: 0 !important;
      text-align: center;
    }

    @media only screen and (max-width: 767px) {
      margin: 0 32px;

      .dropzone_mobile {
        display: block;
        margin: 0 auto !important;
      }
    }

    .field_wrapper.bottom {
      padding-bottom: #{$body-bottom}px;
    }

    .field_wrapper {
      width: 100%;
      padding-bottom: 18px;

      label.flex {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        margin-bottom: 4px;
        font-family: HiraKakuPro-W6;
        color: $app-color-black;
      }

      &.image {
        margin-top: -20px;
      }

      .weight_wrap {
        padding-bottom: 8px;
      }

      .fishing_spot_wrap {
        padding-bottom: 8px;
      }

      .app_form_label_icon {
        margin-left: 1rem;
      }

      .compliance_container {
        margin-bottom: 18px !important;
      }

      .compliance_wrap {
        margin-bottom: 8px;
      }

      .compliance_checkbox {
        position: relative;
        top: 5px;
        label {
          margin-bottom: 6px;
        }
      }

      .is-nature-radio {
        position: relative;
        top: 3px;
        margin-bottom: 6px;
        label {
          margin-bottom: 6px;
        }
      }

      .is-nature-radio-wrapper {
        padding: {
          top: 8px;
        }
        flex-direction: column;
      }

      .ikejime-water-radio {
        position: relative;
        margin-right: 30px;
        margin-bottom: 3px;
        label {
          margin-bottom: 3px;
        }
      }

      .ikejime-water-radio-wrapper {
        padding: {
          top: 8px;
        }
        flex-direction: column;
      }

      .compliance_label {
        padding-left: 10px;
        font-size: 14px;
        cursor: pointer;
        position: relative;
      }
      .fish_select {
        padding-left: 10px;
        font-size: 14px;
        cursor: pointer;
        position: relative;
        top: -7px;
        margin-right: 30px;
      }
    }

    .field_wrapper.description {
      padding-bottom: 64px;
    }

    .form_last {
      padding-bottom: 64px;
    }
  }

  .turn_landing.flex {
    display: flex;
    justify-content: center;
    width: 100%;
    margin: 36px 0 30px;

    a {
      color: $app-color-black;

      &:visited {
        color: $app-color-black;
      }
    }
  }

  .description textarea {
    width: 100%;
    resize: none;
    border: solid 1px #ccc;
    border-radius: 5px;
  }

  .btn_sell {
    height: 48px;
    line-height: $app-line-height-main;
    color: white;
    background-color: #ffc107;
    border-radius: 5px;

    @include hover_button_opacity;

    &:hover {
      @include mq_down('sm') {
        opacity: 1;
      }
    }
  }

  a {
    &:hover {
      text-decoration: underline;
      opacity: 0.7;

      @include mq_down('sm') {
        text-decoration: none;
        opacity: 1;
      }
    }
  }

  form.flex {
    .ui.input {
      width: 550px;
    }

    .ui.selection.dropdown {
      // semantic-ui-reactのcss上書き
      width: 550px;
    }

    .dropzone.flex {
      width: 100%;
      height: 320px;
    }

    .field_wrapper {
      width: 550px;
      margin: 0;
    }
    .field_wrapper.image_group {
      padding-bottom: 26px;
    }
  }

  @media screen and (min-width: 1024px) {
    form.flex {
      width: 550px;

      .ui.input {
        width: 550px;
      }

      .ui.selection.dropdown {
        // semantic-ui-reactのcss上書き
        width: 550px;
      }

      .dropzone.flex {
        width: 100%;
        height: 320px;
      }

      .field_wrapper {
        width: 550px;
        margin: 0;
      }

      .field_wrapper.image {
        margin-top: -20px;
      }
    }
  }

  @media screen and (max-width: 767px) {
    padding: 0 16px;

    .wrap {
      width: 100%;
    }

    .app_titlebar {
      width: 100%;
      height: auto;
    }

    .ui.header {
      font-size: 16px;
    }

    form.flex {
      width: 100%;
      margin: 0;

      .ui.input {
        // semantic-ui-reactのcss上書き
        width: 100%;
      }

      .ui.selection.dropdown {
        // semantic-ui-reactのcss上書き
        width: 100%;
      }

      .dropzone.flex {
        width: 100%;
        height: 300px;
      }

      .dropzone_pc {
        display: none;
      }

      .dropzone_mobile {
        display: block;
      }

      .field_wrapper {
        width: 100%;
        padding-bottom: 20px;

        label.flex {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          width: 380px;
          margin-bottom: 4px;
          font-family: HiraKakuPro-W6;
        }

        .app_form_label_icon {
          margin-left: 1rem;
        }
      }

      .field_wrapper.description {
        padding-bottom: 56px;
      }
    }

    .turn_landing.flex {
      padding: 36px 0 32px;
      margin: 0;
      background: white;
    }

    .btn_sell {
      width: 100%;
    }
  }
}

.stack_checkbox {
  display: flex;
  flex-wrap: wrap;
  gap: 0.25em 1.25em;
}
